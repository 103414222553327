import { forwardRef } from "react";

import { cn } from "~/lib/ui";

const TRANSPARENT_PIXEL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=";
export const getImageMediaLink = (
  imageUrl: string,
  {
    quality,
    params,
  }: {
    quality?: number;
    params: Record<string, string>;
  },
) => {
  if (!imageUrl) return TRANSPARENT_PIXEL;
  if (!URL.canParse(imageUrl.startsWith("//") ? "https:" + imageUrl : imageUrl))
    return TRANSPARENT_PIXEL;
  const url = new URL(
    imageUrl.startsWith("//") ? "https:" + imageUrl : imageUrl,
  );
  let searchParams = url.searchParams;
  if (quality) url.searchParams.set("qlt", quality.toString());
  if (params)
    searchParams = new URLSearchParams({
      ...Object.fromEntries(searchParams),
      ...params,
    });

  return url.origin + url.pathname + "?" + searchParams.toString();
};
const calculateScreenSize = (
  size: number,
  maxWidth: "1/2" | "2/3" | "full",
) => {
  return Math.ceil(
    size * (maxWidth === "1/2" ? 0.5 : maxWidth === "2/3" ? 0.66 : 1),
  );
};
export const ContentfulImage = forwardRef<
  HTMLPictureElement,
  React.HTMLAttributes<HTMLPictureElement> & {
    imageUrl: string;
    mobileImageUrl?: string;
    alt: string;
    lazy?: boolean;
    small?: boolean;
    maxWidth: "1/2" | "2/3" | "full";
  }
>(
  (
    {
      imageUrl,
      lazy = true,
      alt,
      small,
      maxWidth,
      className,
      mobileImageUrl,
      ...props
    },
    ref,
  ) => {
    return imageUrl ? (
      <picture className={cn("h-full w-full", className)} ref={ref} {...props}>
        {small ? (
          // Small images are used for the product list page
          <source
            media="(min-width:767px)"
            srcSet={getImageMediaLink(imageUrl, {
              params: {
                q: "100",
                w: calculateScreenSize(600, maxWidth).toString(),
                fm: "webp",
              },
            })}
          />
        ) : (
          <source
            media="(max-width:600px)"
            srcSet={getImageMediaLink(mobileImageUrl || imageUrl, {
              params: {
                q: "100",
                w: calculateScreenSize(600, maxWidth).toString(),
                fm: "webp",
              },
            })}
          />
        )}
        <source
          media="(max-width:767px)"
          srcSet={getImageMediaLink(mobileImageUrl || imageUrl, {
            params: {
              q: "100",
              w: calculateScreenSize(767, maxWidth).toString(),
              fm: "webp",
            },
          })}
        />
        <source
          media="(max-width:1023px)"
          srcSet={`${getImageMediaLink(imageUrl, {
            params: {
              q: "85",
              w: calculateScreenSize(1024, maxWidth).toString(),
              fm: "webp",
            },
          })}`}
        />
        <source
          media="(max-width:1223px)"
          srcSet={`${getImageMediaLink(imageUrl, {
            params: {
              q: "85",
              w: calculateScreenSize(1224, maxWidth).toString(),
              fm: "webp",
            },
          })}`}
        />
        <source
          media="(max-width:1799px)"
          srcSet={`${getImageMediaLink(imageUrl, {
            params: {
              q: "85",
              w: calculateScreenSize(1800, maxWidth).toString(),
              fm: "webp",
            },
          })}`}
        />
        <source
          media="(min-width:1800px)"
          srcSet={`${getImageMediaLink(imageUrl, {
            params: {
              q: "100",
              w: calculateScreenSize(2560, maxWidth).toString(),
              fm: "webp",
            },
          })}`}
        />
        <img
          src={getImageMediaLink(imageUrl, {
            params: {
              q: "100",
              w: calculateScreenSize(800, maxWidth).toString(),
              fm: "webp",
            },
          })}
          alt={alt}
          className={cn(
            "block h-full w-full object-cover object-center",
            className,
          )}
          loading={lazy === false ? "eager" : "lazy"}
        />
      </picture>
    ) : null;
  },
);
ContentfulImage.displayName = "ContentfulImage";
